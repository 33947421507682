import "./css/service.css";
import "./css/queries.css";

function Service() {
  return (
    <>
      <div className="service-card-top-container">
        <div className="">
          <div className="service-card-container">
            <div className="">
              <div className="service-text-header">
                Is your app running slow?
              </div>
              <div className="service-text-body">
                <div>
                  Slow application performance may reduce customers' interest in
                  visiting or using your application, and this could reduce your
                  revenue.
                </div>

                <div className="service-eng-text">
                  Centurywaves engineering team can help optimize your app
                  performance, thereby increasing your client base for optimal
                  revenue & profit!
                </div>

                <div>Contact Us.</div>
              </div>
            </div>

            <div className="">
              <div className="service-text-header">Software Engineering</div>

              <div className="service-text-body">
                <div>
                  At Centurywaves, our engineering team designs enterprise
                  applications of all sizes, from small monoliths to complex
                  distributed microservices.
                </div>
                <div className="service-eng-text">
                  Our experienced architects, Quality Assurance and Scrum team,
                  work closely with our Frontend - UI/UX, Backend and DevOps
                  engineers to ensure secure, quality and on time delivery of
                  products to the market.
                </div>

                <div>learn more</div>
              </div>
            </div>
            <div className=""></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Service;
