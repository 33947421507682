import "./css/menuBar.css";
import "./css/queries.css";
import { IoMenu } from "react-icons/io5";
// import { BsPerson } from "react-icons/bs";
import { RiArrowDropDownLine } from "react-icons/ri";
import { TbWorldSearch } from "react-icons/tb";
import { TbWorld } from "react-icons/tb";
import { FaSearch } from "react-icons/fa";

function MenuBar() {
  return (
    <div className="menu-container">
      <div className="menu-bar-outer-container">
        <div className="menu-bar-container">
          <div className="menu-bar-logo">
            <IoMenu className="menu-icon" />
            <div className="menu-service-dropdown">Explore Services</div>
            <RiArrowDropDownLine className="menu-drop-down" />
          </div>
          <div>
            {/* <div className="comp-name">C E N T U R Y W A V E S</div> */}
            <div className="comp-name">CENTURYWAVES</div>
            {/* <div>ABOUT US</div>
            <div>SERVCES</div>
            <div>INDUSTRIES</div>
            <div>LATEST NEWS</div>
            <div>CONTACT US</div> */}
          </div>
          <div className="menu-login">
            {/* <div>Help</div> */}

            {/* <div className="menu-account"> */}
            {/* <div>Login</div> */}
            {/* <BsPerson className="login-icon" /> */}
            
            <TbWorldSearch className="world-search-icon" />
            <FaSearch className="search-icon" />
            <TbWorld className="world-icon" />

            {/* <RiArrowDropDownLine className="menu-bar-search" /> */}
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MenuBar;
