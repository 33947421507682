import "./css/SocialMedia.css";

function SocialMedia() {
  return (
    <>
      <div className="social-main-container">
        <div className="social-inner-container">
          <div className="under-maintena">
            <div>Under maintenance, please try again later</div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SocialMedia;
