import "./css/card.css";
import "./css/queries.css";
import img1 from "../images/paid-card1.jpg";
import img2 from "../images/paid-hero-v1.jpg";
import img3 from "../images/paid-2.jpg";

// import { CgWebsite } from "react-icons/cg";
// import { IoIosCloudUpload } from "react-icons/io";
// import { GrCloudComputer } from "react-icons/gr";

function Card() {
  return (
    <div className="card-container">
      <div className="card-service-main-container">
        <div className="card-service-container">
          <div className="card-service-shadow">
            <div className="icon-title-wrapper">
              <div className="card-pics">
                <div>
                  <img
                    className="card-img"
                    src={img1}
                    alt="agile team high five"
                  />
                </div>
                <div className="card-text">
                  <div className="card-text-header">Business</div>
                </div>
                <div className="card-text-content">
                  Our business team can work with you to grow your business
                  revenue by introducing or modenizing your existing online
                  technology.
                </div>
                <br />
                <div className="card-text-content">Learn more..</div>
              </div>
            </div>
          </div>

          <div className="card-service-shadow">
            <div className="icon-title-wrapper">
              <div className="card-pics">
                <div>
                  <img
                    className="card-img"
                    src={img2}
                    alt="agile team high five"
                  />
                </div>
                <div className="card-text">
                  <div className="card-text-header">Products</div>
                </div>
                <div className="card-text-content">
                  Centurywaves product team work closely with clients for real
                  time feedback and incremental product delivery.
                </div>
                <br />
                <div className="card-text-content">Learn more..</div>
              </div>
            </div>
          </div>

          <div className="card-service-shadow">
            <div className="icon-title-wrapper">
              <div className="card-pics">
                <div>
                  <img
                    className="card-img"
                    src={img3}
                    // 100% * 350px/ 16px =
                    alt="agile team high five"
                  />
                </div>
                <div className="card-text">
                  <div className="card-text-header">Delivery</div>
                </div>
                <div className="card-text-content">
                  We are focused on timely delivery of quality products. Our
                  delivery team work closely with Product, Software Engineering
                  and DevOps team.
                </div>
                <br />
                <div className="card-text-content">Learn more..</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Card;
