import "./css/management.css";

function Management() {
  return (
    <>
      <div className="change-management-main-container">
        <div className="change-management-inner-container">
          <div className="under-maintena">
            <div>Under maintenance, please try again later</div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Management;
