// import Demo from "./pages/Demo";
import HomePage from "./pages/HomePage";
// import Product from "./demo/Product";
import { Helmet } from "react-helmet";

// import Home from "./demo1/Home";

function App() {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Software Engineering Soluions</title>
        <meta
          name="description"
          content="Design & develop modern enterprise applications / migrate existing, onboard newly built applications to cloud"
        />
        <link rel="canonical" href="https://www.centurywaves.com" />
      </Helmet>

      <HomePage />
      {/* <Demo /> */}
    </div>
  );
}

export default App;
