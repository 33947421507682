import "./css/hero.css";
import heroImage from "../images/paid-0-min-updated.png";

function Hero() {
  return (
    <div className="hero-container">
      <div className="hero-head-container">
        <img
          className="hero-image"
          alt="a lady with her computer wearing white dress and a eye glasses"
          src={heroImage}
        />
      </div>
    </div>
  );
}

export default Hero;
