import "./css/intro.css";
import "./css/queries.css";

function Intro() {
  return (
    <>
      {/* <div className="intro-title-container">Make Your App Run faster!</div> */}
      <div className="intro-container">
        <div className="intro-title-container">
          {/* Under maintenance, please try after some time. */}
          <div className="intro-title-container-div">
            Building Business Software
          </div>
          <div className="intro-title-text">
            At Centurywaves, we specialize in delivering top-notch software
            engineering solutions tailored to meet your business needs.
          </div>
          <div className="intro-text-hide-mobile">
            Our team of experts is dedicated to driving innovation and
            efficiency through technology.
          </div>
          <div>
            <a className="btn-link btn-learn-more" href="#">
              Learn More
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default Intro;
