import "./css/footer.css";

import { FaRegCopyright } from "react-icons/fa6";
import { MdMarkEmailRead } from "react-icons/md";
import { BsTelephoneForward } from "react-icons/bs";
import { MdFax } from "react-icons/md";
import { SlSocialInstagram } from "react-icons/sl";
import {
  TiSocialTumblerCircular,
  TiSocialFacebook,
  TiSocialLinkedin,
} from "react-icons/ti";

function Footer() {
  return (
    <div className="footer-container">
      <div className="footer-top">
        <div>
          <div className="footer-top-contact-us">connect with us</div>
          <div className="footer-top-line">
            <hr />
          </div>
          <div className="footer-top-socials">
            <div>
              <TiSocialFacebook />
            </div>
            <div>
              <TiSocialLinkedin />
            </div>
            <div>
              <SlSocialInstagram />
            </div>
            <div>
              <TiSocialTumblerCircular />
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="footer-middle">
          <div>
            <div>USEFUL LINKS</div>
            <div className="footer-middle-items">
              <ul>
                <li>
                  <a className="link-link" href="#">
                    Home
                  </a>
                </li>
                <li>
                  <a className="link-link" href="#">
                    About Us
                  </a>
                </li>
                <li>
                  <a className="link-link" href="#">
                    Our Partners
                  </a>
                </li>
                <li>
                  <a className="link-link" href="#">
                    Industries
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div>
            <div>OUR SERVICES</div>
            <div className="footer-middle-items">
              <ul>
                <li>
                <a className="link-link" href="#">
                  Cloud Services</a></li>

                  <li>
                  <a className="link-link" href="#">
                    System Design
                  </a>
                </li>
                <li>
                  <a className="link-link" href="#">
                    Software Engineering
                  </a>
                </li>

                <li>
                  <a className="link-link" href="#">
                    Cyber Security
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div>
            <div>CONNECTIONS</div>
            <div className="footer-middle-items">
              <ul>
                <li><a className="link-link" href="#">Project Management</a></li>
                <li>
                  <a className="link-link" href="#">
                    Agile Team
                  </a>
                </li>
                <li>
                  <a className="link-link" href="#">
                    Architects
                  </a>
                </li>
                <li>
                  <a className="link-link" href="#">
                    Developers
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div>
            <div>CONTACT</div>
            <div className="footer-middle-items">
              {/* <ul>
                <li><a className="link-link" href="#">Inquiry</a></li>
              </ul> */}
              <div className="footer-phone">
                <MdMarkEmailRead />{" "}
                <div>
                  <a className="btn-link" href="#">
                    Contact Us
                  </a>
                </div>
              </div>
              <div className="footer-phone">
                <BsTelephoneForward />{" "}
                <div>
                  <a className="btn-link" href="#">
                    Let's talk
                  </a>
                </div>
                {/* <div>+ 1 (877) 813-4544</div> */}
              </div>
              <div className="footer-phone">
                <MdFax />

                <div>
                  <a className="btn-link" href="#">
                    Fax your request
                  </a>
                </div>
                {/* <div>+ 1 (214) 975-1601</div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <div className="footer-inner">
          <div>
            Copyright <FaRegCopyright />
          </div>
          <div>{new Date().getFullYear()} All rights reserved</div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
