import "./css/style.css";
import "./css/queries.css";

import FormComponent from "../components/FormComponent";
import Footer from "../components/Footer";
import OurService from "../components/OurService";
import Management from "../components/Management";
import Card from "../components/Card";
import MenuBar from "../components/MenuBar";
import OurServiceTitle from "../components/OurServiceTitle";
import Hero from "../components/Hero";
import Intro from "../components/Intro";
import Service from "../components/Service";
import SocialMedia from "../components/SocialMedia";
import SocialMediaFooter from "../components/SocialMediaFooter";

function HomePage() {
  return (
    <>
      <div className="container">
        <div className="main-container">
          <MenuBar />

          <Hero />

          <Intro />
  
          <Card />

          <Service />

          {/* <OurServiceTitle /> */}

          <Management />
          {/* <FormComponent /> */}
          <SocialMedia />
          {/* <SocialMediaFooter /> */}
          <Footer />
        </div>
      </div>
    </>
  );
}

export default HomePage;
